<template>
  <div class="event-marketing-report">
    <PageTitle title="事件行銷發放報表" btn="匯出發放紀錄" @btnClick="showExportOptions = true" />

    <div class="section-wrapper">
      <YearStatistic :loading="loading.year" :yearMetrics="yearMetrics" />
      <TimeRangeStatistic :rangeMetrics="rangeMetrics" :loading="loading.range" @refresh="getRangeMetrics" />
      <eventTable :tableData="rangeMetrics" :loading="loading.range" />
      <eventRecordList @search="updatePayload" />
    </div>
    <ExportOptionsDialog
      v-if="showExportOptions"
      :useExportTask="useExportTask"
      info="提醒：資料匯出上限為500,000筆，若超過則會匯出最新的500,000筆。"
      @close="showExportOptions = false"
      @export="prepareExport"
    />
    <ExportDialog
      v-if="showExportDialog"
      :inProgress="exportting"
      :isError="exportError"
      :percentage="exportPercentage"
      :data="exportData"
      :total="exportTotal"
      @close="resetExport"
    />
  </div>
</template>

<script>
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import { defineComponent, onMounted, ref, reactive, computed } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import YearStatistic from './components/YearStatistic.vue'
import TimeRangeStatistic from './components/TimeRangeStatistic.vue'
import eventTable from './components/EventTable.vue'
import eventRecordList from './components/EventRecordList.vue'
import { CreateEventPlaybookAwardRecordSheetExportTask } from '@/api/exportTask'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { useRangeTime } from '@/use/rangeTime'
import dayjs from '@/lib/dayjs'
import { get, find } from 'lodash'
import { GetEventPlaybookAwardRecordStatistics, GetEventPlaybookAwardRecordCount, GetEventPlaybookAwardRecord } from '@/api/eventMarketing'
import { ExportExcel } from '@/utils/excel'
import { formatDate } from '@/utils/date'
import { eventAwardConfig, eventTypeConfig } from '@/config/marketing'
import { usePermissions } from '@/use/permissions'
import { useExportCenter } from '@/use/useExportCenter'

export default defineComponent({
  name: 'EventMarketingReport',
  components: {
    PageTitle,
    YearStatistic,
    TimeRangeStatistic,
    eventTable,
    eventRecordList,
    ExportOptionsDialog,
    ExportDialog,
  },
  setup (props) {
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const { messageOptions } = useExportCenter()
    const { getTodayRange } = useRangeTime()
    const showExportOptions = ref(false)
    const showExportDialog = ref(false)
    const exportting = ref(false)
    const exportError = ref(false)
    const yearMetrics = ref({})
    const rangeMetrics = ref({})
    const exportTotal = ref({})
    const exportData = ref({})
    const exportPercentage = ref(0)
    const recordPayload = ref({})
    const useExportTask = computed(() => checkAction('admin.eventPlaybookAwardRecord.createSheetExportTask'))
    const loading = reactive({
      year: false,
      range: false,
    })
    const getRangeMetrics = async (e) => {
      const dates = e.date || e
      loading.range = true
      simpleFetch(GetEventPlaybookAwardRecordStatistics, {
        shopId: shopId.value,
        startCreatedAt: dates[0],
        endCreatedAt: dates[1],
      }, res => {
        rangeMetrics.value = res
        loading.range = false
      })
    }
    const updatePayload = (payload) => {
      recordPayload.value = payload
    }
    const prepareExport = async ({ all, range }) => {
      showExportOptions.value = false
      let start
      let end
      if (!all) {
        start = range[0]
        end = range[1]
      }
      // 取得匯出數據數量
      const payload = {
        shopId: shopId.value,
        startCreatedAt: start,
        endCreatedAt: end,
      }
      if (useExportTask.value) {
        const newPayload = {
          ...payload,
          ...recordPayload.value,
          eventTemplateConfigId: get(recordPayload.value, 'EventTemplateConfigId'),
          createdAtStart: get(recordPayload.value, 'startCreatedAt'),
          createdAtEnd: get(recordPayload.value, 'endCreatedAt'),
        }
        const [, err] = await CreateEventPlaybookAwardRecordSheetExportTask(newPayload)
        if (err) {
          window.$message.error(err)
          return
        }
        window.$message(messageOptions.value)
      } else {
        const [res, err] = await GetEventPlaybookAwardRecordCount(payload)
        if (err) window.$message.error(err)
        exportTotal.value = res.count
        showExportDialog.value = true
        // console.log(exportTotal.value)
        if (!exportTotal.value) {
          window.$message.warning('沒有資料可以匯出')
          return resetExport()
        }
        exportting.value = true
        const [data, error] = await getExportData({ all, range })
        if (error) {
          window.$message.error(error)
          exportting.value = false
          exportError.value = true
          return
        }
        exportting.value = false
        exportData.value = data
        await formatExportData()
      }
    }
    const resetExport = () => {
      showExportDialog.value = false
      exportting.value = false
      exportError.value = false
      exportData.value = []
      exportPercentage.value = 0
    }
    const getExportData = async ({ all, range }) => {
      let start
      let end
      if (!all) {
        start = range[0]
        end = range[1]
      }
      const payload = {
        shopId: shopId.value,
        startCreatedAt: start,
        endCreatedAt: end,
      }
      return await GetEventPlaybookAwardRecord(payload)
    }
    const formatExportData = async () => {
      const data = []
      let count = 0
      for (const item of exportData.value) {
        const row = await formatRecordData(item)
        data.push(row)
        count++
        exportPercentage.value = (count / exportTotal.value) * 100
      }
      const fileName = '事件行銷發放紀錄'

      ExportExcel(data, fileName, fileName)
    }
    const formatRecordData = async (i) => {
      const awardType = get(i, 'awardType')
      const awardConfig = find(eventAwardConfig, { value: awardType })
      const marketingItemLabel = get(awardConfig, 'label') || '-'

      const source = get(i, 'EventPlaybook.EventTemplateConfig.source')
      const typeConfig = find(eventTypeConfig, { value: source })
      const eventTypeLabel = get(typeConfig, 'label') || '-'
      return {
        會員名稱: get(i, 'Member.UserInfo.name'),
        會員手機: get(i, 'Member.UserInfo.phone') || '-',
        事件類型: eventTypeLabel,
        活動名稱: get(i, 'EventPlaybook.EventTemplateConfig.name') || '-',
        行銷項目類型: marketingItemLabel,
        行銷項目名稱: get(i, 'awardName') || '-',
        發送數量: `+${get(i, 'awardAmount')}`,
        發送狀態: get(i, 'isSend') ? '發送成功' : '發送失敗',
        發送日期: get(i, 'sendAt') ? formatDate(get(i, 'sendAt')) : '-',
        備註: get(i, 'errorMessage') || '-',
      }
    }

    onMounted(async () => {
      loading.year = true
      simpleFetch(GetEventPlaybookAwardRecordStatistics, {
        shopId: shopId.value,
        startCreatedAt: dayjs().startOf('y').toDate(),
        endCreatedAt: dayjs().endOf('y').add(1, 's').toDate(),
      }, res => {
        yearMetrics.value = res
        loading.year = false
      })

      const todayDates = getTodayRange()
      getRangeMetrics(todayDates)
    })

    return {
      yearMetrics,
      rangeMetrics,
      getRangeMetrics,
      showExportOptions,
      showExportDialog,
      prepareExport,
      exportting,
      exportError,
      exportTotal,
      exportData,
      exportPercentage,
      resetExport,
      loading,
      useExportTask,
      updatePayload,
    }
  },
})
</script>

<style lang="postcss" scoped>
.section-wrapper {
  @apply flex flex-col gap-[40px];
}
</style>
